import React, { Component, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './TransactionDetailScreen.css';
import withProtection from './hoc/WithProtection';
import { withRouter } from 'react-router-dom';
import ProfileAPI from '../api/ProfileAPI';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import withSingleColumnsLayout from './hoc/WithSingleColumnLayout';
import TransactionHeaderCard from './parts/tx/TransactionHeaderCard';
import { TransactionPaymentCard } from '../comp/card/tx/TransactionPaymentCard';
import NPCard from '../comp/card/NPCard';
import NPLabeledValue from '../comp/NPLabeledValue';
import MarketplaceAPI from '../api/MarketplaceAPI';
import { Navigator } from '../comp/layout/Navigator';
import { HSpacer } from '../comp/layout/HSpacer';
import Cookies from 'universal-cookie';
import { NPCardMessage } from '../comp/card/NPCardMessage';
import { PropertyTransferCard } from '../comp/card/tx/PropertyTransferCard';
import { NPNotificationCard } from '../comp/card/NPNotificationCard';
import { ReactComponent as TickSVG } from '../icons/tick.svg';
import { CarrierInfoCard } from '../comp/card/tx/CarrierInfoCard';
import { isBuyer, isSeller } from '../util/UserUtil';
import { IbanCard } from '../comp/card/tx/IbanCard';
import { SellerPaidCard } from '../comp/card/tx/SellerPaidCard';

const cookies = new Cookies()

function Content() {

    const [transaction, setTransaction] = useState()
    const [ptJustConfirmed, setPTJustConfirmed] = useState(false);

    const location = useLocation()
    const history = useHistory()

    const tid = location.state.tid;

    const init = () => {
        loadTransactionAndProfiles();
    }

    /**
     * Load the transaction data
     */
    const loadTransactionAndProfiles = async () => {

        const apiResponse = await new MarketplaceAPI().getMyTransaction(tid);

        setTransaction(apiResponse);
    }

    /**
     * When the property transfer has been confirmed by the user: 
     * - Show a notification
     * - Reload the transaction
     */
    const onPropertyTransferConfirmed = () => {

        setPTJustConfirmed(true);

        init();

    }

    useEffect(init, [])

    if (!transaction) return <div></div>



    return (
        <div className="admin-transaction-detail-screen">

            <HSpacer />
            <Navigator backLabel="Torna alla dashboard" onBack={() => { history.push('/dashboard') }} />
            <HSpacer />

            <TransactionHeaderCard transaction={transaction} />

            <TxDetailsCard transaction={transaction} />

            <TransactionPaymentCard transaction={transaction} />

            <NPNotificationCard icon={<TickSVG />} message="La registrazione dello scambio di proprietà è avvenuta." trigger={ptJustConfirmed} />

            {transaction.buyerPaidOn != null && isBuyer(transaction) && transaction.buyerConfirmedPTOn == null &&
                <NPCard>
                    <NPCardMessage text="E' ora possibile procedere con lo scambio del bene oggetto della Compravendita." instructions={<>Organizzati con la tua Controparte per lo scambio del bene. Una volta effettuato lo scambio, <b>conferma di aver concluso la transazione</b> (qui sotto) e Pleggit procederà allo <b>svincolo dei fondi</b> una volta ricevuta la conferma da entrambe le parti.</>}></NPCardMessage>
                </NPCard>
            }
            {transaction.buyerPaidOn != null && isSeller(transaction) && transaction.sellerConfirmedPTOn == null &&
                <NPCard>
                    <NPCardMessage text="E' ora possibile procedere con lo scambio del bene oggetto della Compravendita." instructions={<>Organizzati con la tua Controparte per lo scambio del bene. Una volta effettuato lo scambio, <b>conferma la consegna</b> (qui sotto). Una volta ricevuta la conferma di ricezione da parte del Compratore, Pleggit procederà allo <b>svincolo dei fondi</b>.</>}></NPCardMessage>
                </NPCard>
            }

            {transaction.sellerConfirmedPTOn != null && <CarrierInfoCard transaction={transaction} />}

            <PropertyTransferCard tx={transaction} onPropertyTransferConfirmed={onPropertyTransferConfirmed} />

            <IbanCard transaction={transaction} onIBANSaved={loadTransactionAndProfiles}/>

            <SellerPaidCard transaction={transaction} />

            {/* {transaction.buyerConfirmedPTOn && transaction.sellerConfirmedPTOn && <AgencySellerPaymentCard transaction={transaction} />} */}

            {/* <div className="two-cols">
                <div className="col left">
                    <div className="card-container"><ProfileForTxCard loading={buyerProfileLoading} email={transaction.buyerEmail} profile={buyerProfile} partyType="buyer" highlight={true} highlightColor="primary" /></div>
                </div>
                <div className="col right">
                    <div className="card-container"><ProfileForTxCard loading={sellerProfileLoading} email={transaction.sellerEmail} profile={sellerProfile} partyType="seller" highlight={true} /></div>
                </div>
            </div> */}

        </div >
    )
}

function TxDetailsCard({ transaction }) {

    const loggedUser = cookies.get('user');

    let fee = transaction.buyerFee;
    if (loggedUser.userId == transaction.sellerUserId) fee = transaction.sellerFee;

    return (
        <NPCard>
            <div className='fees-card-content'>
                <NPLabeledValue label="Creata il" value={transaction.createdOn} type="date" />
                <NPLabeledValue label="Commissioni Pleggit" value={fee} type="currency" />
            </div>
        </NPCard>
    )
}

export default withProtection(
    withSingleColumnsLayout(
        withRouter(withTranslation()(Content)),
        "narrow"
    )
);
